import React from 'react';


export const DeadPage = () => {
  return (
    <div className="deadpage">
      <h1>Ånei!</h1>
      <h1>Dere er ute av spillet!! 😱</h1>
      <h1>Livestream på kontoret 🥳</h1>
    </div>
  )
}
