import React, { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import styled from "styled-components";

export const Login = () => {
  const { login } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await login(username, password);
  };

  return (
    <LoginPage>
      <LoginForm onSubmit={onSubmit}>
        {/* <label>
          Username
          <input
            type="text"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
        </label>
        <label>
          Password
          <input
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </label> */}
        <InputField
          label={"Brukernavn"}
          type="text"
          value={username}
          onChange={setUsername}
        />
        <InputField
          label={"Passord"}
          type="password"
          value={password}
          onChange={setPassword}
        />
        <button type="submit">Login</button>
      </LoginForm>
    </LoginPage>
  );
};

const InputField = ({
  label,
  type,
  value,
  onChange,
}: {
  label: string;
  type: string;
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </>
  );
};

const LoginPage = styled.div`
  height: 50vh;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    font-weight: 600;
  }

  input {
    background-color: #c9e1e6;
    margin-bottom: 8px;
    padding: 8px 6px;
    border-radius: 4px;
    border: none;
  }
`;
