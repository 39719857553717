import React, {ReactNode} from "react";
import "../style.css";
import styled from "styled-components";
import {useQuery} from "@tanstack/react-query";
import {getApiUrl} from "../util/hostname";
import {bFetch} from "../util/client";
import {Status} from "./BTasks";

interface Bar {
  id: number;
  col: number;
  row: number;
  name: string;
}

type IsHit = "attack" | "preserve";

interface Ship {
  id: number;
  team_id: number;
  row: number;
  col: number;
  status: "alive" | "sunk";
}

export interface TaskComplete {
  id: number;
  task_id: number;
  row: number;
  col: number;
  player: string;
  timestamp: string;
  type: IsHit;
}

interface BarObj {
  id: number;
  name: string;
  col: number;
  row: number;
  isHit: IsHit | undefined;
  hasHitShip: boolean;
  hasShip: boolean;
}

interface SunkShips {
  row: number;
  col: number;
}

const rowName = ["A", "B", "C", "D", "E", "F", "G"];

export const BMap = () => {
  let bars: Bar[] = [];
  bars = useQuery({
    queryKey: ["bars"],
    queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/bars`); //
      return result.json();
    },
  }).data;

  const tasksComplete: TaskComplete[] = useQuery({
    queryKey: ["completed-tasks"],
    queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/completed-tasks`); //
      return result.json();
    },
  }).data;

  const status: Status = useQuery({
    queryKey: ["status"],
    queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/status`); //
      return result.json();
    },
  }).data;
  const teamShips: Ship[] = useQuery({
    queryKey: ["teamShips"],
    queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/team-ships`); //
      return result.json();
    },
  }).data;

  const sunkShips: SunkShips[] = useQuery({
    queryKey: ["sunkShips"],
    queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/sunk-ships`); //
      return result.json();
    },
  }).data;

  if (status) {
    if (status.alive === 0) {
      return (
        <div style={{padding: "24px"}}>
          <h2> U dead</h2>
          <p>{" Kom til kontoret :)"} </p>
        </div>
      );
    }
  }

  let barObj: BarObj[] | undefined;

  if (bars) {
    if (tasksComplete) {
      barObj = composeBarObjs(bars, tasksComplete, teamShips, sunkShips);
    } else {
      barObj = bars.map((b) => ({
        ...b,
        isHit: undefined,
        hasShip: false,
        hasHitShip: false,
      }));
    }
  } else {
    barObj = undefined;
  }

  return (
    <MapDiv>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2> Bars</h2>
        <StatusBar alive={status?.alive} sunk={status?.sunk}/>
      </div>
      <Map bars={barObj}/>
      <BarList bars={bars}/>
    </MapDiv>
  );
};

const StatusBar = ({
                     alive,
                     sunk,
                   }: {
  alive: number | undefined;
  sunk: number | undefined;
}) => {
  if (!alive || !sunk) {
    return <></>;
  }
  return (
    <h4>
      HP: {alive} / {alive + sunk}
    </h4>
  );
};

const BarList = ({bars}: { bars: Bar[] }) => {
  return (
    <div>
      <h2> Bars </h2>
      <table>
        <tbody>
        {bars?.map((bar) => {
          return (
            <BarTR id={"li" + bar.name} key={"li" + bar.name}>
              <BarTD>
                {rowName[bar.row - 1]}
                {bar.col}
              </BarTD>
              <BarTD> • </BarTD>
              <BarTD> {bar.name}</BarTD>
            </BarTR>
          );
        })}
        </tbody>
      </table>
    </div>
  );
};

function composeBarObjs(
  barList: Bar[],
  tasksComplete: TaskComplete[] = [],
  teamShips: Ship[],
  sunkShips: SunkShips[]
): BarObj[] {
  let bars: BarObj[] = barList.map((bar) => {
    let isHit = tasksComplete.find((task) => {
      return task.col === bar.col && task.row === bar.row;
    });

    let hasShip = teamShips
      ? teamShips.some((ship) => {
        return bar.col === ship.col && bar.row === ship.row;
      })
      : false;

    let hasSunkenShip = sunkShips
      ? sunkShips.some((ship) => {
        return bar.col === ship.col && bar.row === ship.row;
      })
      : false;

    return {
      ...bar,
      isHit: isHit?.type,
      hasShip: hasShip,
      hasHitShip: hasSunkenShip,
    };
  });

  return bars;
}

const Map = ({bars}: { bars: BarObj[] | undefined }) => {
  if (!bars) {
    return <></>;
  }

  let mapSize = 7;
  let barInCoords: BarObj[][] = new Array(mapSize);

  for (let i = 0; i < mapSize; i++) {
    barInCoords[i] = new Array(mapSize);
  }

  bars.map((bar) => {
    barInCoords[bar.row - 1][bar.col - 1] = bar;
  });

  const rows = barInCoords.map((row, i) => {
    return <MapRows row={row} key={`row-${i}`}/>;
  });

  return <>{rows}</>;
};

const MapRows = ({row}: { row: BarObj[] }) => {
  const mapRow = row.map((bar) => {
    return (
      <Square
        isHit={bar.isHit}
        hasSunkShip={bar.hasHitShip}
        hasBoat={false}
        key={bar.id}
        size={row.length}
      >
        <LocationText href={"#li" + bar.name} isHit={false}>
          {bar.hasShip
            ? `🛥`
            : `${rowName[bar.row - 1]}${bar.col}`}
        </LocationText>
      </Square>
    );
  });
  return <div style={{display: "flex"}}>{mapRow.map((r) => r)}</div>;
};

type SquareProps = {
  hasBoat?: boolean;
  isHit: IsHit | undefined;
  children: ReactNode;
  size: number;
  hasSunkShip: boolean;
};

const Square = (props: SquareProps) => {
  // TODO:: Show the team's boats
  // If has own boat -> white circle and change text to square color.

  const colorHit = props.hasSunkShip ? "#ad3737" : "#b8c5d1";
  const bg = props.isHit ? colorHit : "#63829c";

  return (
    <StyledSquare
      numRows={props.size}
      isHit={props.isHit}
      hasBoat={props.hasBoat}
      hasSunkShip={props.hasSunkShip}
      bgColor={bg}
    >
      {props.children}
    </StyledSquare>
  );
};

const MapDiv = styled.div`
  padding: 24px;
  margin-bottom: 30vh;
  scrollbehavior: smooth;
  block: start;
`;

const LocationText = styled.a<{
  isHit: boolean;
}>`
  color: white;
  font-size: 5vmin;
  font-weight: 600;
  text-decoration: ${(props) =>
  props.isHit ? "line-through solid 2px" : "none"};
`;

const StyledSquare = styled.div<{
  numRows: number;
  isHit: IsHit | undefined;
  hasBoat?: boolean;
  hasSunkShip: boolean;
  bgColor: string;
}>`
  background-color: ${(props) => props.bgColor};
  height: ${(props) => "calc(100vw / " + props.numRows + ")"};
  width: ${(props) => "calc(100vw / " + props.numRows + ")"};
  border-radius: 2px;
  margin: 1px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const BarTR = styled.tr`
  td:nth-child(1) {
    color: #3d5c75;
    font-weight: 600;
  }
`;
const BarTD = styled.td`
  padding: 7px 2px;
`;
