import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getApiUrl } from "../util/hostname";
import { bFetch } from "../util/client";
import { Link } from "react-router-dom";

import "../style.css";
import styled from "styled-components";
import { TaskComplete } from "./BMap";
import { TaskType } from "../models/task";
import { Deadlines } from "./Deadlines";

type TasksC = TaskType & { completedAt: string };
export interface Status {
  alive: number;
  sunk: number;
}

export const Tasks = ({
  gameEnd,
  silentNightStart,
}: {
  gameEnd: number;
  silentNightStart: number;
}) => {
  const tasks: TaskType[] = useQuery({
    queryKey: ["tasks"],
    queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/tasks`); //
      return result.json();
    },
  }).data;
  const teamShips = useQuery({
    queryKey: ["teamShips"],
    queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/team-ships`); //
      return result.json();
    },
  }).data;
  const sunkShips = useQuery({
    queryKey: ["sunkShips"],
    queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/sunk-ships`); //
      return result.json();
    },
  }).data;

  const tasks_complete_objs: TaskComplete[] = useQuery({
    queryKey: ["completed-tasks"],
    queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/completed-tasks`); //
      return result.json();
    },
  }).data;

  let completed_tasks: TasksC[] = [];

  const tasks_filtered = tasks
    ? tasks.filter((task) => {
        return tasks_complete_objs
          ? !tasks_complete_objs.some((tc, i) => {
              let t_id = tc.task_id > 0 ? tc.task_id : tasks[i].id; // for å teste. Kan tas bort og erstatte t_id med tc ? tc.task_id === task.id : false
              if (t_id === task.id) {
                completed_tasks.push({
                  ...tasks[i],
                  completedAt: tc.timestamp,
                });
                return true;
              }
              return false;
            })
          : false;
      })
    : [];

  return (
    <div style={{ padding: 24 }}>
      <Deadlines gameEnd={gameEnd} silentNightStart={silentNightStart} />
      <Task title="Ledige oppgaver" tasks={tasks_filtered} />
      <TaskCompleteList tasks={completed_tasks} />
    </div>
  );
};

const Task = ({ title, tasks }: { title: string; tasks: TaskType[] }) => {
  const sortedList = tasks.sort((x, y) => {
    const xt = x.timestamp.split("T")[1].split(".")[0].split(":"); // <-- Dårlig kode, men lite tid
    const yt = y.timestamp.split("T")[1].split(".")[0].split(":"); // <-- Dårlig kode, men lite tid

    // Mer dårlig kode, hehe
    if (xt[0] !== yt[0]) {
      return parseInt(xt[0]) - parseInt(yt[0]);
    } else if (xt[1] !== yt[1]) {
      return parseInt(xt[1]) - parseInt(yt[1]);
    }
    return parseInt(xt[2]) - parseInt(yt[2]);
  });

  return (
    <div>
      <h3> {title} </h3>

      {tasks && (
        <ul className="taskList">
          {sortedList.map((task) => {
            return (
              <li key={task.id}>
                <StyledLink
                  className={"taskItem"}
                  to={{
                    pathname: `how-to-shoot/${task.id}`,
                  }}
                >
                  <div>{task.name}</div>
                  <div style={{ color: "#32415c" }}> {`ID: ${task.id}`}</div>
                </StyledLink>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const TaskCompleteList = ({ tasks }: { tasks: TasksC[] }) => {
  const sortedList = tasks.sort((x, y) => {
    const xt = x.completedAt.split("T")[1].split(".")[0].split(":"); // <-- Dårlig kode, men lite tid
    const yt = y.completedAt.split("T")[1].split(".")[0].split(":"); // <-- Dårlig kode, men lite tid

    // Mer dårlig kode, hehe
    if (xt[0] !== yt[0]) {
      return parseInt(xt[0]) - parseInt(yt[0]);
    } else if (xt[1] !== yt[1]) {
      return parseInt(xt[1]) - parseInt(yt[1]);
    }
    return parseInt(xt[2]) - parseInt(yt[2]);
  });

  return (
    <div>
      <h3> Fullførte oppgaver </h3>
      {tasks && (
        <ul className="taskList">
          {sortedList.map((task) => {
            return (
              <li key={task.id}>
                <div className={"taskItem"}>{task.name}</div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;
