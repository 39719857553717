import React from "react";

function formatTime(ms: number): string {
  let seconds = ms / 1000;
  const hours = Math.floor(seconds / 3600);
  seconds = seconds % 3600;
  const minutes = Math.floor(seconds / 60);
  seconds = Math.round(seconds % 60);
  const parts = [];
  if (hours > 0) {
    parts.push(hours);
  }
  parts.push(minutes.toString().padStart(2, "0"));
  parts.push(seconds.toString().padStart(2, "0"));
  return parts.join(":");
}

export const Deadlines = ({gameEnd, silentNightStart}: { gameEnd: number, silentNightStart: number }) => {
  return (
    <div className="deadlines">
      <h3>Spillets slutt: {formatTime(gameEnd)}</h3>
      {
        silentNightStart > 0 && (
          <h3>Stille natt: {formatTime(silentNightStart)}</h3>
        )
      }
    </div>
  )
}

export const SilentNight = ({silentNightEnd}: { silentNightEnd: number }) => {
  return (
    <div className="silent-night">
      <h1>Nå er det stille natt! Du kan slappe av 😌</h1>
      <h2>Tid igjen: {formatTime(silentNightEnd)}</h2>
    </div>
  )
}
