import React, { ReactNode } from "react";
import "../style.css";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { getApiUrl } from "../util/hostname";
import { bFetch } from "../util/client";
import { useParams } from "react-router-dom";

export const HowTo = () => {
  let { id } = useParams();

  const howToText = [
    "Slack: @bbirle <type> <koordinat, typ B3> <oppgavenr>",
    "type: attack || preserve",
    `Oppgavenummer til denne oppgaven: ${id}`,

    "Bevis (bilde/video) på at laget har løst oppgaven og på riktig utested sendes på Slack-kanalen.",
  ];

  return (
    <HowToDiv>
      <h2>Hvordan (be)skyt(t)e </h2>
      <ol>
        {howToText.map((text) => (
          <li key={text.substring(0, 20)}>{text}</li>
        ))}
      </ol>
    </HowToDiv>
  );
};

const HowToDiv = styled.div`
  padding: 24px;
`;
