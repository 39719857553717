import ErrorPage from "./error-page";
import {RouterProvider, createBrowserRouter} from "react-router-dom";
import {BHeader} from "./Components/BHeader";
import {BRules} from "./Components/BRules";
import {BMap} from "./Components/BMap";
import {Tasks} from "./Components/BTasks";
import {QueryClient, QueryClientProvider, useQuery} from "@tanstack/react-query";
import {Login} from "./Components/Login";
import {useAuth} from "./hooks/useAuth";
import {HowTo} from "./Components/HowTo";
import {StatusType} from "./models/status";
import {bFetch} from "./util/client";
import {getApiUrl} from "./util/hostname";
import {DeadPage} from "./Components/DeadPage";
import {SilentNight} from "./Components/Deadlines";
import {useInterval} from "usehooks-ts";
import {useState} from "react";
import {GameEnd} from "./Components/GameEnd";

interface Deadlines {
  gameEnd?: string;
  silentNightStart?: string;
  silentNightEnd?: string;
}

const timeToEpoch = (time: string): number => {
  const [hour, minute] = time.split(":");
  return new Date().setHours(parseInt(hour), parseInt(minute), 0, 0);
}

const calculateTimeLeft = (time: string): number => {
  const epoch = timeToEpoch(time);
  return (epoch - Date.now());
}

const queryClient = new QueryClient();

export const App = () => {

  return (
    <QueryClientProvider client={queryClient}>
      <InnerApp/>
    </QueryClientProvider>
  );
};

const InnerApp = () => {
  const {auth} = useAuth();
  const teamStatus: StatusType | undefined = useQuery({
    queryKey: ['status'],
    queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/status`); //
      return result.json();
    }
  }).data;

  const deadlines = useQuery({
    queryKey: ["deadlines"], queryFn: async () => {
      const result = await bFetch(`${getApiUrl()}/deadlines`);
      return result.json();
    }
  }).data;

  const [gameEnd, setGameEnd] = useState(1);
  const [silentNightStart, setSilentNightStart] = useState(-1);
  const [silentNightEnd, setSilentNightEnd] = useState(-1);

  useInterval(() => {
      if (!deadlines || Object.keys(deadlines).length === 0) {
        return;
      }
      setGameEnd(calculateTimeLeft(deadlines.gameEnd));
      setSilentNightStart(calculateTimeLeft(deadlines.silentNightStart));
      setSilentNightEnd(calculateTimeLeft(deadlines.silentNightEnd));
    },
    1000,
  )

  if (!auth) {
    return <Login/>;
  }

  const boats = teamStatus?.boats || 0;
  const sunk = teamStatus?.sunk || 0;

  if (boats > 0 && sunk === boats) {
    return <DeadPage/>;
  }

  if (silentNightStart < 0 && silentNightEnd > 0) {
    return <SilentNight silentNightEnd={silentNightEnd}/>
  }

  if (gameEnd < 0) {
    return <GameEnd/>;
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Tasks gameEnd={gameEnd} silentNightStart={silentNightStart}/>,
      errorElement: <ErrorPage/>,
    },
    // {
    //   path: "/status",
    //   element: <BStatus />,
    //   errorElement: <ErrorPage />,
    // },
    {
      path: "/how-to-shoot/:id",
      element: <HowTo/>,
      errorElement: <ErrorPage/>,
    },
    {
      path: "/map",
      element: <BMap/>,
      errorElement: <ErrorPage/>,
    },
    {
      path: "/rules",
      element: <BRules/>,
      errorElement: <ErrorPage/>,
    },
  ]);

  return (
    <>
      <BHeader/>
      <RouterProvider router={router}/>
    </>
  );
}
