import React from 'react';


export const GameEnd = () => {
  return (
    <div className="silent-night">
      <h1>Spillet er ferdig!🥳</h1>
      <h1>Gå tilbake til kontoret for å hedre vinneren!</h1>
    </div>
  )
}
