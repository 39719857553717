// import React from "react";
import { useRouteError } from "react-router-dom";
import sammy from "./samoyedPC.jpg";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div id="error-page" style={{ textAlign: "center" }}>
      <h1>
        {error.status} • {error.statusText || error.message}
      </h1>
      <img src={sammy} alt="404 - samoyed with a computer" width="500" />
    </div>
  );
}
