import React from "react";
import styled from "styled-components";

const rules = [
  {
    title: "Målet med spillet",
    rules: [
      "Overleve som siste person med (deler av) båter",
      "Ødelegge andres båter",
    ],
  },
  {
    title: "Forberedelser til spillet",
    rules: [
      "Laget må velge hvilke koordinater båtene skal stå på. Hvert lag får to båter, én som er 3 ruter lang og én som er 2 ruter lang. Rutene for hver båt må stå inntil hverandre i en vertikal eller horisontal linje.",
      "Før start melder du fra til arrangørene (de setter opp en stand) hvilke koordinater laget har valgt.",
    ],
  },
  {
    title: "Spillmekanikker",
    rules: [
      "Få tilgang på å skyte/frede båter: Koordinater i kartet tilsvarer barer/utesteder i Oslo, cirka sentrum og Grunerløkka. For å skyte på et koordinat, ref “Skyte båter”, må man velge en oppgave, et utested, og løse oppgaven på det utestedet. Bevis (bilde/video) på at laget har løst oppgaven og på riktig utested sendes på Slack-kanalen.",
      "Skyte båter: På Slack sender man kommandoer på formen `@bbirle attack <koordinat, typ B3> <oppgavenr>` sammen med bevis på løsning på nevnte oppgavenr (bilde/video), så vil et skudd avfyres på koordinatet dere oppga",
      "Frede båter: Tilsvarende som “Skyte båter”, bare bytte ut nøkkelord “attack” med “preserve” for å frede baren fra å bli skutt på de neste 30 minuttene. Forsøk på å løse oppgaver på denne baren i denne tidsperioden blir avvist.",
    ],
  },
  {
    title: "Løse oppgaver",
    rules: [
      "Man må sende bevis (bilde/video) på løst oppgave på Slack (man kan slette bevisene etter konkurransen dersom ønskelig)",
      "Etter en oppgave er løst av et lag kan man ikke løse den igjen, heller ikke andre lag. Ja, selv om den var tilgjengelig da du begynte å løse den, det er førstemann til mølla og førstemann til å levere bevis som blir godkjent som får godkjent.",
      "Ikke press noen på laget ditt til å gjøre oppgaver de ikke har lyst til å gjøre",
      "Behandle folk med respekt! Både andre spillere, gjester, og ansatte på stedene vi besøker. Ikke lag unødvendig mye uro.",
    ],
  },
  {
    title: "Behandle andre spillere",
    rules: [
      "Vær grei mot andre spillere selv om det er konkurranse",
      "Selv om vi er på en del barer er det helt greit å ikke drikke alkohol, og ingen skal utøve drikkepress i noen som helst form",
      "Ikke skaff deg info som åpenbart ikke skal være tilgjengelig for spillerne",
    ],
  },
  {
    title: "Når man har tapt!? Eller vunnet?",
    rules: [
      "Kom tilbake til kontoret for å følge med på live-streamen fra konkurransen ✌️",
      "Premieseremoni etter vinnerlaget ankommer kontoret 🏆",
    ],
  },
];

export const BRules = () => {
  return (
    <RuleDiv>
      <h1>Regler</h1>
      <>
        {rules.map((r) => {
          return (
            <div key={r.title}>
              <h2>{r.title}</h2>
              <SubRules ruleObj={r} />
            </div>
          );
        })}
      </>
    </RuleDiv>
  );
};

const SubRules = ({
  ruleObj,
}: {
  ruleObj: { title: string; rules: string[] };
}) => (
  <ul>
    {ruleObj.rules.map((r) => (
      <li key={`${ruleObj.title}-${r.substring(0, 6)}`}> {r} </li>
    ))}
  </ul>
);

const RuleDiv = styled.div`
  padding: 24px;
  margin-bottom: 26vh;
  h2 {
    padding-top: 2rem;
  }

  li {
    padding-bottom: 1rem;
  }
`;
