export const mainDark = "#463f3a";
export const mainMedium = "#8a817c";
export const mainLight = "#bcb8b1";
export const secondary = "#e0afa0";
export const bright = "#f4f3ee";

export const colors = {
  headerBG: "#ffffff",
  headerText: "#3647a6",

  bodyBG: "#ffffff",
  bodyTitleText: "#2e5a43",

  taskText: "#584f3d",
  taskBG: "#f2f2f2",
  taskBorder: "#ced4d9",
};

export const spacing = {
  defaultPadding: "24px",
};
